import React, { useEffect, useState, useRef, useCallback } from 'react';
import './AddContent.css';
import { Link } from 'react-router-dom';
import isURL from 'validator/lib/isURL';
import RiseLoader from 'react-spinners/RiseLoader';
import '../../../../Assets/css/common.css';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ArrowLeft from '../../../../Assets/ContentLibrary/UploadContent/arrow-left.png';
import AddContentSelection from '../../../../components/AddContentSelection/AddContentSelection';
import TextInput from '../../../../components/TextInput/TextInput';
import TileSearch from '../../../../components/TileSearch/TileSearch';
import RadioButtonGroup from '../../../../components/RadioButtonGroup/RadioButtonGroup';
import WebUrl from '../../../../components/WebUrl/WebUrl';
import UploadThumbnail from '../../../../components/UploadThumbnail/UploadThumbnail';
import BrowseFile from '../../../../components/BrowseFile/BrowseFile';
import { cmdsFileUpload } from '../../../../FileUploads/cmdsFileUpload';
import {
  fetchBoards,
  fetchCourse,
  fetchSubjects,
  fetchSubSubjects,
  fetchTopics,
  fetchSubTopics,
  fetchObjective,
  fetchMediaList,
  fetchRadioButtons,
  fetchFileTypeList,
  fetchConcept,
} from '../../../../api/master_data.js';
import { uploadContent, editContent /* addSession */ } from '../../../../api/content';
import ContentQuestionTimerModal from '../../../../components/ContentQuestionTimerModal/ContentQuestionTimerModal';
import Chips from '../../../../components/PlayKitButton/PlayKitButton.js';

const AddContent = (props) => {
  const { editContentDetails, toggleEditComponent, getUpdatedValues, handleToggle } = props;

  const [loading, setLoading] = useState(false);
  const [boards, setBoards] = useState([]);
  const [board_id, setBoardId] = useState(editContentDetails ? editContentDetails.board_id : -1);
  const [courses, setCourses] = useState([]);
  const [course_id, setCourseId] = useState(editContentDetails ? editContentDetails.course_id : -1);
  const [subjects, setSubjects] = useState([]);
  const [subject_id, setSubjectId] = useState(editContentDetails ? editContentDetails.subject_id : -1);
  const [sub_subjects, setSubSubjects] = useState([]);
  const [sub_subject_id, setSubSubjectId] = useState(editContentDetails ? editContentDetails.sub_subject_id : -1);
  const [topicOne, setTopicOne] = useState('');
  const [topicOne_id, setTopicOne_id] = useState(editContentDetails ? editContentDetails.topic_1_id : -1);
  const [subTopicOne, setSubTopicOne] = useState('');
  const [subTopicOne_id, setSubTopicOne_id] = useState(editContentDetails ? editContentDetails.sub_topic_1_id : -1);
  const [topicTwo, setTopicTwo] = useState('');
  const [topicTwo_id, setTopicTwo_id] = useState(editContentDetails ? editContentDetails.topic_2_id : -1);
  const [subTopictwo, setSubTopicTwo] = useState('');
  const [subTopicTwo_id, setSubTopicTwo_id] = useState(editContentDetails ? editContentDetails.sub_topic_2_id : -1);
  const [objective, setObjective] = useState([]);
  const [objectiveId, setObjectiveId] = useState(editContentDetails ? editContentDetails.objective_id : -1);
  const [media, setMedia] = useState([]);
  const [media_id, setMediaId] = useState(editContentDetails ? editContentDetails.media_id : -1);
  const [contentFileTypeId, setContentFileTypeId] = useState(editContentDetails ? editContentDetails.content_file_type_id : -1);
  const [url, setUrl] = useState(
    toggleEditComponent && editContentDetails['link_type'] === 'WEBLINK' ? editContentDetails.content_url : '',
  );
  const [file, setFile] = useState(null);
  const [filePath, setFilePath] = useState(
    toggleEditComponent && editContentDetails['link_type'] === 'FILE' ? editContentDetails.content_url : '',
  );
  const [thumbnail, setThumbNail] = useState(null);
  const [thumbNailPath, setThumbNailPath] = useState(
    toggleEditComponent && editContentDetails ? editContentDetails.thumbnail_url : '',
  );
  const [contentId, setContentId] = useState(editContentDetails ? editContentDetails.content_id : 'New Content');
  const [displayName, setDisplayName] = useState(editContentDetails ? editContentDetails.display_name : '');
  const [description, setDescription] = useState(editContentDetails ? editContentDetails.description : '');
  const [readingTime, setReadingTime] = useState(editContentDetails ? editContentDetails.duration_in_min : '');
  const [tileSearch, setTileSearch] = useState(editContentDetails ? editContentDetails.tile_caption : '');
  const [clicked, setClicked] = useState(editContentDetails ? editContentDetails.access_level_id : 1);
  const [radio, setRadio] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [openQuestionTimerModal, setOpenQuestionTimerModal] = useState(false);
  const [conceptList_1, setConceptList_1] = useState([]);
  const [concept_1_id, setConcept_1_id] = useState(
    editContentDetails ? editContentDetails?.concept_1_details?.map((i) => i.concept_id) || [] : [],
  );

  const [conceptList_2, setConceptList_2] = useState([]);
  const [concept_2_id, setConcept_2_id] = useState(
    editContentDetails ? editContentDetails?.concept_2_details?.map((i) => i.concept_id) || [] : [],
    [],
  );

  const [link_type, setLinkType] = useState(
    toggleEditComponent && editContentDetails.link_type ? editContentDetails.link_type : '',
  );
  const [questionTimerDetails, setQuestionTimerDetails] = useState(
    toggleEditComponent && editContentDetails.hasOwnProperty('question_times')
      ? editContentDetails.question_times.length > 0
        ? editContentDetails.question_times
        : [{ duration: '0', time_min: '', time_sec: '', question_id: '' }]
      : [{ duration: '0', time_min: '', time_sec: '', question_id: '' }],
  );

  const institution_id = localStorage.getItem('institution_id');
  const user_id = localStorage.getItem('user_id');
  let err = {};
  let contentFileType = useRef([]);

  useEffect(() => {
    const fetchAll = async () => {
      setLoading(true);
      setBoards(await fetchBoards());
      setCourses(await fetchCourse(board_id));
      setSubjects(await fetchSubjects(board_id, course_id));
      setSubSubjects(await fetchSubSubjects(board_id, course_id, subject_id));
      setTopicOne(await fetchTopics(board_id, course_id, subject_id, sub_subject_id));
      setSubTopicOne(await fetchSubTopics(board_id, course_id, subject_id, sub_subject_id, topicOne_id));
      setTopicTwo(await fetchTopics(board_id, course_id, subject_id, sub_subject_id));
      setSubTopicTwo(await fetchSubTopics(board_id, course_id, subject_id, sub_subject_id, topicTwo_id));
      setObjective(await fetchObjective());
      setMedia(await fetchMediaList());
      setRadio(await fetchRadioButtons());
      setConceptList_1(await fetchConcept(subTopicOne_id));
      setConceptList_2(await fetchConcept(subTopicTwo_id));
      setLoading(false);
    };
    try {
      fetchAll();
    } catch (err) {
      console.log('err in AddContent fetchAll', err);
      toast.error('Something went wrong try again');
      if (err?.message) {
        toast.error(err.message);
      }
      setLoading(false);
    }
  }, [
    board_id,
    course_id,
    subject_id,
    sub_subject_id,
    topicOne_id,
    subTopicOne_id,
    topicTwo_id,
    subTopicTwo_id,
    objectiveId,
    media_id,
  ]);

  useEffect(() => {
    const getTypeId = async () => {
      setLoading(true);
      contentFileType.current = await fetchFileTypeList();
      setLoading(false);
    };
    try {
      getTypeId();
    } catch (err) {
      console.log('err in AddContent getTypeId', err);
      toast.error('Something went wrong try again');
      if (err?.message) {
        toast.error(err.message);
      }
      setLoading(false);
    }
  }, []);

  const loadPrevDrpDown = async (values, id_name) => {
    const selectedDropDown = editContentDetails[id_name];
    if (id_name !== 'topic_1_id' && id_name !== 'sub_topic_1_id' && id_name !== 'topic_2_id' && id_name !== 'sub_topic_2_id') {
      for (let index = 0; index < values.length; index++) {
        const obj = values[index];
        if (obj[id_name] === selectedDropDown) {
          obj.selected = true;
        } else {
          delete obj.selected;
        }
      }
      setLoading(true);
      if (id_name === 'board_id') setBoards(values);
      if (id_name === 'course_id') setCourses(values);
      if (id_name === 'subject_id') setSubjects(values);
      if (id_name === 'sub_subject_id') setSubSubjects(values);
      if (id_name === 'objective_id') setObjective(values);
      if (id_name === 'media_id') setMedia(values);
      setLoading(false);
    } else {
      if (id_name === 'topic_1_id' || id_name === 'topic_2_id') {
        for (let index = 0; index < values.length; index++) {
          const obj = values[index];
          if (obj.topic_id === selectedDropDown) {
            obj.selected = true;
          } else {
            delete obj.selected;
          }
        }

        if (id_name === 'topic_1_id') setTopicOne(values);
        if (editContentDetails.topic_2_id && id_name === 'topic_2_id') setTopicTwo(values);
      } else if (id_name === 'sub_topic_1_id' || id_name === 'sub_topic_2_id') {
        for (let index = 0; index < values.length; index++) {
          const obj = values[index];
          if (obj.sub_topic_id === selectedDropDown) {
            obj.selected = true;
          } else {
            delete obj.selected;
          }
        }
        setLoading(true);
        if (editContentDetails.sub_topic_1_id && id_name === 'sub_topic_1_id') setSubTopicOne(values);
        if (editContentDetails.sub_topic_2_id && id_name === 'sub_topic_2_id') setSubTopicTwo(values);
        setLoading(false);
      }
    }
  };

  const loadIntialValues = useCallback(async () => {
    setLoading(true);
    await loadPrevDrpDown(boards, 'board_id');
    await loadPrevDrpDown(courses, 'course_id');
    await loadPrevDrpDown(subjects, 'subject_id');
    await loadPrevDrpDown(sub_subjects, 'sub_subject_id');
    (await editContentDetails.topic_1_id) && loadPrevDrpDown(topicOne, 'topic_1_id');
    (await editContentDetails.sub_topic_1_id) && loadPrevDrpDown(subTopicOne, 'sub_topic_1_id');
    (await editContentDetails.topic_2_id) && loadPrevDrpDown(topicTwo, 'topic_2_id');
    (await editContentDetails.sub_topic_2_id) && loadPrevDrpDown(subTopictwo, 'sub_topic_2_id');
    await loadPrevDrpDown(objective, 'objective_id');
    await loadPrevDrpDown(media, 'media_id');
    setLoading(false);
    // eslint-disable-next-line
  }, [boards, courses, subjects, sub_subjects, topicOne, subTopicOne, topicTwo, subTopictwo, objective, media]);

  useEffect(() => {
    setLoading(true);
    toggleEditComponent && loadIntialValues();
    setLoading(false);
    // eslint-disable-next-line
  }, [loadIntialValues]);

  // console.log("boards", boards, "boardId", board_id)
  const handleBoardIdChange = async (e) => {
    let boardId = e.target.value;
    setBoardId(boardId);
    if (toggleEditComponent) {
      await getUpdatedValues({
        ...editContentDetails,
        board_id: boardId,
      });
    }
    setLoading(false);
  };

  const handleCourseIdChange = (e) => {
    let courseId = e.target.value;
    setCourseId(courseId);
    setLoading(false);
  };

  const handleSubjectIdChange = (e) => {
    let subjectId = e.target.value;
    setSubjectId(subjectId);
    setLoading(false);
  };

  const handleSubSubjectChange = (e) => {
    let subSubjectId = e.target.value;
    setSubSubjectId(subSubjectId);
    setLoading(false);
  };

  const handleTopicOneChange = (e) => {
    let topicOneId = e.target.value;
    setTopicOne_id(topicOneId);
    setLoading(false);
  };

  const handleSubTopicOneIdChange = (e) => {
    let subTopicOne = e.target.value;
    setSubTopicOne_id(subTopicOne);
    setLoading(false);
  };

  const handleTopicTwoChange = (e) => {
    let topicTwoId = e.target.value;
    setTopicTwo_id(topicTwoId);
    setLoading(false);
  };

  const handleSubTopicTwoIdChange = (e) => {
    let subTopicTwoId = e.target.value;
    setSubTopicTwo_id(subTopicTwoId);
    setLoading(false);
  };

  const handleObjectiveIdChange = async (e) => {
    let objectiveId = e.target.value;
    setObjectiveId(objectiveId);
    if (toggleEditComponent) {
      await getUpdatedValues({
        ...editContentDetails,
        objective_id: objectiveId,
      });
    }
    setLoading(false);
  };

  const handleMediaIdChange = async (e) => {
    let mediaId = e.target.value;
    setMediaId(mediaId);
    if (toggleEditComponent) {
      await getUpdatedValues({
        ...editContentDetails,
        media_id: mediaId,
      });
    }
    setLoading(false);
  };

  const handleTextInputChange = (e) => {
    let attr = e.target.name;
    if (attr === 'displayName') {
      setDisplayName(e.target.value);
    } else if (attr === 'description') {
      setDescription(e.target.value);
    } else if (attr === 'readingTime') {
      setReadingTime(e.target.value);
    }
  };

  const findContentFileType = async (file) => {
    let fileName = file.name;
    let extension = `.${fileName}`.substring(fileName.lastIndexOf('.') + 1);
    for (let indx = 0; indx < contentFileType.current.length; indx++) {
      const element = contentFileType.current[indx];
      let suffixArray = element.suffixes.split(';');
      for (let index = 0; index < suffixArray.length; index++) {
        const ext = suffixArray[index];
        // eslint-disable-next-line
        if (ext == extension) {
          let matchingId = element.content_type_id;
          setContentFileTypeId(matchingId);
          return matchingId;
        }
      }
    }
  };

  const handleFileUpload = async (e) => {
    setLoading(true);
    let uploadedFile = e.target.files[0];
    if (uploadedFile.size > 10e8) {
      toast.error('File size should be less than 100mb');
    }
    try {
      setFile(uploadedFile);
      findContentFileType(uploadedFile);
      await cmdsFileUpload(uploadedFile, setFilePath, null);
      setLinkType('FILE');
      setLoading(false);
      setUrl('');
    } catch (err) {
      toast.error(err?.message);
    }
    if (toggleEditComponent) {
      setUrl('');
      filePath.trim().length !== 0 && toast.success('File uploaded successfully!!');
    }
  };

  const handleFileRemove = (e) => {
    e.preventDefault();
    setFile(null);
    setFilePath('');
    if (toggleEditComponent) toast.success('File removed successfully!!');
  };

  const handleUrlChange = (e) => {
    setLinkType('WEBLINK');
    setUrl(e.target.value);
    setFile(null);
    setFilePath('');
    setContentFileTypeId(-1);
    if (toggleEditComponent) {
      setContentFileTypeId(-1);
    }
  };

  const handleUploadThumbnail = async (e) => {
    let uploadedThumbnail = e.target.files[0];
    if (uploadedThumbnail) {
      setLoading(true);
      setThumbNail(uploadedThumbnail);
      await cmdsFileUpload(uploadedThumbnail, setThumbNailPath, 'thumbnail');
      setLoading(false);
    }
    toast.success('Thumbnail changed successfully!!');
    // if (toggleEditComponent) {
    //     toast.success("Thumbnail changed successfully!!")
    // }
  };

  const handleChecked = (e) => {
    setClicked(e.target.value);
  };

  const handleModalOpenClose = () => {
    setOpenQuestionTimerModal(!openQuestionTimerModal);
  };

  const runValidations = async () => {
    if (+board_id === -1) {
      err.board = 'Please select Board';
    }
    // if (concept_1_id?.length === 0) {
    //   err.concept_1 = 'Please select Concept';
    // }
    if (+course_id === -1) {
      err.course = 'Please select course';
    }
    if (+subject_id === -1) {
      err.subject = 'Please select subject';
    }
    if (+topicOne_id === -1) {
      err.topicOne = 'Please select topic one';
    }
    if (+objectiveId === -1) {
      err.objective = 'Please select objective';
    }
    if (+media_id === -1) {
      err.media = 'Please select Media type';
    }
    if (displayName.trim().length === 0) {
      err.displayName = 'Display name cannot be empty';
    }
    // if (description.trim().length === 0) {
    //     err.description = "Please provide description"
    // }
    if (toString(readingTime).trim().length === 0) {
      err.readingTime = 'Provide minimum reading time';
    }
    if (toggleEditComponent) {
      if (filePath.trim().length === 0 && url.trim().length === 0) {
        err.file = 'Please upload either file or web url';
      }
    } else {
      if (file === null && url.trim().length === 0) {
        err.file = 'Please upload either file or web url';
      }
      if (file === null && url.trim().length === 0 && isURL(url)) {
        err.file = 'Please enter valid url';
      }
      if (file !== null && filePath.trim().length === 0) {
        err.file = 'Please upload your file once again';
      }
    }
    // if (toggleEditComponent) {
    //     if (filePath.trim().length === 0 && url.trim().length === 0) {
    //         err.file = "Please upload either file or web url"
    //     }
    // } else if (file == null && url.trim().length === 0) {
    //     err.file = "Please upload either file or web url"
    // }
    // if (file === null && url.trim().length === 0 && isURL(url)) {
    //     err.file = "Please enter valid url"
    // }
    // if (file !== null && filePath.trim().length === 0) {
    //     err.file = "Please upload your file once again"
    // }
    if (thumbnail === null && !toggleEditComponent) {
      err.thumbnail = 'Please provide thumbnail';
    }
    if (thumbNailPath.trim().length === 0) {
      err.thumbnail = 'Please upload thumbnail once again';
    }
    // if (tileSearch.trim().length === 0) {
    //     err.tileSearch = "Tile Caption cannot be empty"
    // }
  };

  const resetForm = async () => {
    setMediaId(-1);
    setBoardId(-1);
    setObjectiveId(-1);
    setContentId('New Content');
    setDisplayName('');
    setDescription('');
    setReadingTime('');
    setFile(null);
    setUrl('');
    setThumbNail(null);
    setTileSearch('');
    setClicked(1);
    setQuestionTimerDetails([
      {
        duration: '',
        time_min: '',
        time_sec: '',
        question_id: '',
      },
    ]);
  };
  console.log('questionTimerDetails', questionTimerDetails);
  const handleSubmit = async (e) => {
    e.preventDefault();
    runValidations();
    if (Object.keys(err).length === 0) {
      setFormErrors({});

      let formData = {
        display_name: displayName,
        description: description,
        tile_caption: tileSearch,
        institution_id: +institution_id,
        board_id: +board_id,
        course_id: +course_id,
        subject_id: +subject_id,
        sub_subject_id: +sub_subject_id,
        topic_1_id: +topicOne_id,
        sub_topic_1_id: +subTopicOne_id,
        topic_2_id: +topicTwo_id,
        sub_topic_2_id: +subTopicTwo_id,
        objective_id: +objectiveId,
        added_by_user_id: user_id,
        content_url: filePath ? filePath : url.trim(),
        duration_in_min: +readingTime,
        thumbnail_url: thumbNailPath,
        access_level_id: +clicked,
        content_file_type_id: +contentFileTypeId,
        media_id: +media_id,
        file: file,
        thumbnail: thumbnail,
        link_type: link_type,
        concept_1_ids: concept_1_id?.length > 0 ? JSON.stringify(concept_1_id) : null,
        concept_2_ids: concept_2_id?.length > 0 ? JSON.stringify(concept_2_id) : null,
        question_times:
          questionTimerDetails.length > 0 &&
          (questionTimerDetails[0].time_min?.length !== 0 ||
            questionTimerDetails[0].time_sec?.length !== 0 ||
            questionTimerDetails[0].question_id?.length !== 0)
            ? questionTimerDetails
            : [],
      };

      console.log('formData', formData);
      if (toggleEditComponent) {
        setLoading(true);
        await editContent(editContentDetails.content_id, formData);
        // link_type === "WEBLINK" && await addSession(formData)
        setLoading(false);
      } else {
        setLoading(true);
        await uploadContent(formData);
        // link_type === "WEBLINK" && await addSession(formData)
        setLoading(false);
      }
      await resetForm();
      if (toggleEditComponent) {
        toast.success('Content edited successfully!');
        setTimeout(() => {
          window.location.href = '/content-library';
        }, 3000);
        setLoading(false);
      } else {
        toast.success('Content added successfully!');
        setLoading(false);
        setTimeout(() => {
          window.location.href = '/content-library';
        }, 3000);
      }
    } else {
      toast.error('Please provide required details properly!');
      setFormErrors(err);
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="loading__spinners">
          <RiseLoader
            color="#CC435F"
            margin={5}
            cssOverride={{
              position: 'absolute',
              top: '50%',
              left: '45%',
              zIndex: 1,
            }}
            loading={loading}
            size={50}
            speedMultiplier={1}
          />
        </div>
      ) : null}
      <div className="add-content">
        <div className="add-content__header">
          <Link to={toggleEditComponent ?? '/content-library'} onClick={handleToggle}>
            <img className="header-img" src={ArrowLeft} alt="back to content library" />
          </Link>
          {toggleEditComponent ? (
            <div className="header-text">Edit Content</div>
          ) : (
            <div className="header-text">Upload Content</div>
          )}
        </div>
        <form className="add-content__form" onSubmit={handleSubmit}>
          <div className="form-attachment">
            <BrowseFile
              file={file}
              filePath={filePath}
              handleFileUpload={handleFileUpload}
              handleFileRemove={handleFileRemove}
              toggleEditComponent={toggleEditComponent}
              editContentDetails={editContentDetails}
              accept={'.doc, .docx, .xml, .pdf, .ppt, .xls, xlsx, .webm, .pptx, .jpg, .jpeg, .jfif, .png, .mp4'}
              prop_value="content_url"
            />

            <WebUrl value={url} handleUrlChange={handleUrlChange} />
          </div>
          {formErrors.file && (
            <div className="add-content-form-error" style={{ textAlign: 'center', marginBottom: '10px', marginTop: '-10px' }}>
              {formErrors.file}
            </div>
          )}

          <div className="form-inputs">
            <div className="form-left">
              <UploadThumbnail
                thumbnail={thumbnail}
                handleUploadThumbnail={handleUploadThumbnail}
                formErrors={formErrors.thumbnail}
                toggleEditComponent={toggleEditComponent}
                editContentDetails={editContentDetails}
              />

              <div className="form-dropdowns">
                <AddContentSelection
                  name="board"
                  label="Board"
                  default_option="Select"
                  values={boards}
                  id_name="board_id"
                  display_name="board_name"
                  handleIdChange={handleBoardIdChange}
                  formErrors={formErrors}
                  editContentDetails={editContentDetails}
                  setValues={setBoards}
                  id={'board_id'}
                  value={board_id}
                  isRequired={true}
                />
                <AddContentSelection
                  name="course"
                  default_option="Select"
                  label="Course"
                  values={courses}
                  id_name="course_id"
                  display_name="course_name"
                  formErrors={formErrors}
                  handleIdChange={handleCourseIdChange}
                  editContentDetails={editContentDetails}
                  setValues={setCourses}
                  id={'course_id'}
                  value={course_id}
                  isRequired={true}
                />
                <AddContentSelection
                  name="subject"
                  default_option="Select"
                  label="Subject"
                  values={subjects}
                  id_name="subject_id"
                  display_name="subject_name"
                  formErrors={formErrors}
                  handleIdChange={handleSubjectIdChange}
                  editContentDetails={editContentDetails}
                  setValues={setSubjects}
                  id={'subject_id'}
                  value={subject_id}
                  isRequired={true}
                />
                <AddContentSelection
                  name="subsubject"
                  default_option="Select"
                  label="Sub-subject"
                  values={sub_subjects}
                  id_name="sub_subject_id"
                  display_name="sub_subject_name"
                  formErrors={formErrors}
                  handleIdChange={handleSubSubjectChange}
                  editContentDetails={editContentDetails}
                  setValues={setSubSubjects}
                  id={'sub_subject_id'}
                  value={sub_subject_id}
                  display_eng_name="sub_subject_name_eng"
                />
                <AddContentSelection
                  name="topicOne"
                  default_option="Select"
                  label="Topic 1"
                  values={topicOne}
                  id_name="topic_id"
                  display_name="topic_name"
                  formErrors={formErrors}
                  handleIdChange={handleTopicOneChange}
                  editContentDetails={editContentDetails}
                  setValues={setTopicOne}
                  actual_id_name="topic_1_id"
                  id={'topic_1_id'}
                  display_eng_name="topic_name_eng"
                  value={topicOne_id}
                  isRequired={true}
                />
                <AddContentSelection
                  name="subTopicOne"
                  default_option="Select"
                  label="Sub Topic 1"
                  values={subTopicOne}
                  id_name="sub_topic_id"
                  display_name="sub_topic_name"
                  formErrors={formErrors}
                  handleIdChange={handleSubTopicOneIdChange}
                  editContentDetails={editContentDetails}
                  setValues={setSubTopicOne}
                  actual_id_name="sub_topic_1_id"
                  id={'sub_topic_1_id'}
                  value={subTopicOne_id}
                  display_eng_name="sub_topic_name_eng"
                />

                <AddContentSelection
                  name="concept_1"
                  default_option="Select"
                  label="concept 1 "
                  values={conceptList_1}
                  id_name="concept_id"
                  display_name="concept_name"
                  formErrors={formErrors}
                  handleIdChange={(e) => {
                    if (!concept_1_id?.includes(e.target.value)) {
                      setConcept_1_id([...concept_1_id, +e.target.value]);
                    }
                    console.log('dc dc', e.target.value, concept_1_id);
                  }}
                  editContentDetails={editContentDetails}
                  setValues={setSubTopicOne}
                  actual_id_name="concept_1_id"
                  id={'sub_topic_1_id'}
                  value={-1}
                  display_eng_name=""
                  // isRequired={true}
                />

                {
                  /* create && */ concept_1_id && concept_1_id.length > 0 && (
                    <div style={{ paddingBottom: '15px', width: '100%' }}>
                      <Chips
                        playKitArray={conceptList_1.filter((i) => concept_1_id?.includes(i.concept_id))}
                        handleRemovePlayKit={(e) => {
                          console.log(
                            concept_1_id,
                            conceptList_1,
                            e,
                            concept_1_id?.filter((i) => i !== e.toString()),
                          );
                          setConcept_1_id(concept_1_id?.filter((i) => i !== e));
                        }}
                        marginTop={'0px'}
                        marginBottom={'10px'}
                      />
                    </div>
                  )
                }
                <AddContentSelection
                  name="topicTwo"
                  default_option="Select"
                  label="Topic 2"
                  values={topicTwo}
                  id_name="topic_id"
                  display_name="topic_name"
                  formErrors={formErrors}
                  handleIdChange={handleTopicTwoChange}
                  editContentDetails={editContentDetails}
                  setValues={setTopicTwo}
                  actual_id_name="topic_2_id"
                  id={'topic_2_id'}
                  value={topicTwo_id}
                  display_eng_name="topic_name_eng"
                />
                <AddContentSelection
                  name="subTopicTwo"
                  default_option="Select"
                  label="Sub Topic 2"
                  values={subTopictwo}
                  id_name="sub_topic_id"
                  display_name="sub_topic_name"
                  formErrors={formErrors}
                  handleIdChange={handleSubTopicTwoIdChange}
                  editContentDetails={editContentDetails}
                  setValues={setSubTopicTwo}
                  actual_id_name="sub_topic_2_id"
                  id={'sub_topic_2_id'}
                  value={subTopicTwo_id}
                  display_eng_name="sub_topic_name_eng"
                />
                <AddContentSelection
                  name="concept_2"
                  default_option="Select"
                  label="concept 2"
                  values={conceptList_2}
                  id_name="concept_id"
                  display_name="concept_name"
                  formErrors={formErrors}
                  handleIdChange={(e) => {
                    if (!concept_2_id?.includes(e.target.value)) {
                      setConcept_2_id([...concept_2_id, +e.target.value]);
                    }
                    console.log('dc dc', e.target.value, concept_2_id);
                  }}
                  editContentDetails={editContentDetails}
                  setValues={setSubTopicOne}
                  actual_id_name="concept_2_id"
                  id={'sub_topic_2_id'}
                  value={-1}
                  display_eng_name="sub_topic_name_eng"
                />
                {
                  /* create && */ concept_2_id && concept_2_id.length > 0 && (
                    <div style={{ paddingBottom: '15px', width: '100%' }}>
                      <Chips
                        playKitArray={conceptList_2.filter((i) => concept_2_id?.includes(i.concept_id))}
                        handleRemovePlayKit={(e) => {
                          console.log(
                            concept_2_id,
                            conceptList_2,
                            e,
                            concept_2_id?.filter((i) => i !== e),
                          );
                          setConcept_2_id(concept_2_id?.filter((i) => i !== e));
                        }}
                        marginTop={'0px'}
                      />
                    </div>
                  )
                }
              </div>
            </div>
            <div className="form-right">
              <div className="content-question-time-btn" onClick={handleModalOpenClose}>
                {toggleEditComponent ? 'Add or Edit Question Times' : 'Add Question Times'}
              </div>
              {openQuestionTimerModal && (
                <ContentQuestionTimerModal
                  handleModalOpenClose={handleModalOpenClose}
                  openModal={openQuestionTimerModal}
                  questionTimerDetails={questionTimerDetails}
                  setQuestionTimerDetails={setQuestionTimerDetails}
                />
              )}
              <AddContentSelection
                name="media"
                default_option="Select"
                label="Media"
                values={media}
                id_name="media_id"
                display_name="media_name"
                formErrors={formErrors}
                handleIdChange={handleMediaIdChange}
                editContentDetails={editContentDetails}
                setValues={setMedia}
                id={'media_id'}
                value={media_id}
                isRequired={true}
              />

              <TextInput
                name="contentId"
                label="Content ID"
                formErrors={formErrors}
                type="text"
                value={contentId}
                handleTextInputChange={handleTextInputChange}
                readOnly={true}
              />

              <TextInput
                name="displayName"
                label="Display Name"
                formErrors={formErrors}
                value={displayName}
                type="text"
                handleTextInputChange={handleTextInputChange}
                isRequired={true}
              />

              <TileSearch
                name="tileSearch"
                label="Tile Caption"
                formErrors={formErrors}
                type="text"
                tileSearch={tileSearch}
                setTileSearch={setTileSearch}
                widthinModal={false}
                prop_value="tile_caption"
              />

              <TextInput
                name="description"
                label="Description"
                height={'72px'}
                formErrors={formErrors}
                value={description}
                type="text"
                handleTextInputChange={handleTextInputChange}
              />

              <AddContentSelection
                name="objective"
                default_option="Select"
                label="Objective"
                values={objective}
                id_name="objective_id"
                display_name="objective"
                formErrors={formErrors}
                handleIdChange={handleObjectiveIdChange}
                editContentDetails={editContentDetails}
                setValues={setObjective}
                id={'objective_id'}
                value={objectiveId}
                isRequired={true}
              />

              <TextInput
                name="readingTime"
                label="Reading Time ( in min.)"
                formErrors={formErrors}
                value={readingTime}
                type="number"
                handleTextInputChange={handleTextInputChange}
                isRequired={true}
              />

              <div className="form-right-radio">
                <div className="form-radio-category">Sharing</div>
                <RadioButtonGroup
                  radio={radio}
                  name="sharing"
                  clicked={clicked}
                  handleChecked={handleChecked}
                  access_level_id={'access_level_id'}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="form-submission">
          <Link to="/content-library">
            <button className="cancel-btn" type="reset">
              Cancel
            </button>
          </Link>
          <button className="save-btn" type="submit" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
    </>
  );
};

export default AddContent;
